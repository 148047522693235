form {

  &.submitted {

    input.ng-invalid,
    select.ng-invalid,
    input.error {
      color: $red-700;
      border: 1px solid $red-300;

      &:focus {
        box-shadow: 0 0 0 3px $red-200;
      }
    }

    .ng-invalid .dropdown>input {
      border: 1px solid $red-300;
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: $space-lg;
    margin-bottom: $space-lg;
    padding-bottom: $space;
    border-bottom: 1px solid $neutral-100;

    &.first {
      margin-top: 0;
    }
  }

  .grid {
    display: flex;
    align-items: stretch;
  }

  .column {
    flex: 1;
  }

  .row {
    display: flex;
    margin: 0 calc(-1 * #{$space-xs});
    margin-bottom: $space;
  }

  .message {
    display: flex;
    align-items: center;
    height: 42px;
    margin-bottom: $space;
    border-radius: $rounded;
    padding: $space-xs $space;

    &.error {
      color: $white;
      background-color: $red-100;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-right: $space-sm;
      color: $red-400;
      border-radius: $rounded;
    }

    .text {
      color: $red-700;
      font-weight: 500;
    }
  }

  .field {
    flex-shrink: 0;
    flex: 1;
    margin: 0 $space-xs;

    .labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;

      label {
        color: $neutral-400;
      }

      .error {
        color: $red-600;
      }
    }

    input,
    select,
    textarea {
      width: 100%;
    }

    .text {
      padding: $space-xs 0;
      font-size: $font-sm;
      line-height: 20px;
    }
  }
}
