.empty {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    max-width: 400px;
    padding: $space-md;
    text-align: center;

    img {
      width: 100px;
      margin-bottom: $space-md;
    }

    .title {
      font-family: $font-secondary;
      font-size: $font-md;
      margin-bottom: $space-xs;
    }

    .description {
      text-align: center;
      color: $neutral-600;
    }
  }
}
