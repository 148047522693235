/* core V3 */

@import 'styles/core/spacing';
@import 'styles/core/border-radius';
@import 'styles/core/breakpoint';
@import 'styles/core/color';
@import 'styles/core/reset';
@import 'styles/core/typography';
@import 'styles/core/shadow';
@import 'styles/core/z-index';

/* components V3 */

@import 'styles/components/layout';
@import 'styles/components/input';
@import 'styles/components/form';
@import 'styles/components/button';
@import 'styles/components/toolbar';
@import 'styles/components/navbar';
@import 'styles/components/modal';
@import 'styles/components/card';
@import 'styles/components/empty';
@import 'styles/components/switch';
@import 'styles/components/nucleo';
