.toolbar {
  z-index: $zindex-header;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 74px;
  padding: $space $space-md;
  background-color: $white;
  box-shadow: $shadow;

  app-search {
    margin-left: $space-sm;
  }

  app-dropdown {
    margin-left: $space-sm;
  }

  .commands {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: $space-sm;
    }
  }
}
