button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 38px;
  padding: 0 $space;
  font-size: $font-sm;
  font-weight: 500;
  color: $white;
  white-space: nowrap;
  background-color: $primary-500;
  border: 1px solid $primary-500;
  border-radius: $rounded;
  cursor: pointer;
  outline: 0;
  transition: .3s all ease;

  &:hover {
    background-color: $primary-600;
  }

  &.wide {
    width: 100%;
  }

  &.secondary {
    color: $primary-500;
    background-color: transparent;
  }

  &.red {
    color: #FFF;
    background-color: $red-500;
    border-color: $red-500;
  }

  &.subtle {
    color: $neutral-500;
    background-color: transparent;
    border-color: $neutral-300;
    transition: all .3s ease;
  }

  &.icons {
    display: flex;
    width: 38px;
    min-width: auto;
    padding: 0;
    background-color: $neutral-100;
    border: none;
    color: $neutral-400;

    &:hover {
      color: $white;
      background-color: $primary-500;
    }
  }

  &.load,
  &.ok {
    color: transparent;

    &:before {
      color: $white;
      position: absolute;
      margin-right: 0.5rem;
      font-size: 18px;
      color: $white;
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
    }
  }

  &.load {
    &:before {
      content: "\f1ce";
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
  }

  &.ok {
    &::before {
      content: "\f00c";
    }
  }

  img {
    width: 40px;
  }

  .icon {
    font-size: 18px;
  }
}
