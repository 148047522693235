.navbar {
  z-index: $zindex-header;
  padding: $space-sm $space-md;
  background-color: $white;
  border-bottom: 1px solid $neutral-100;
  box-shadow: $shadow;

  .tab {
    margin-right: $space-md;
    padding-bottom: calc(#{$space-sm} + 4px);
    color: $neutral-300;
    font-weight: 600;
    text-decoration: none;

    &.active {
      color: $primary-500;
      border-bottom: 2px solid $primary-500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
