@font-face {
  font-family: 'Circular';
  font-weight: 400;
  src: url("/assets/fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: 'Circular';
  font-weight: 600;
  src: url("/assets/fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("/assets/fonts/fa-light-300.ttf") format("truetype");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/assets/fonts/fa-regular-400.ttf") format("truetype");
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/assets/fonts/fa-solid-900.ttf") format("truetype");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}


// font family
$font-primary: '-apple-system',
'system-ui';

$font-secondary: 'Circular',
sans-serif;

:root {
  --font-sm: 14px;
}

// type scale
$font-xs: 11px;
$font-sm: 14px;
$font-base: 16px;
$font-md: 18px;
$font-lg: 20px;
$font-xl: 24px;
$font-xxl: 36px;
$font-xxxl: 48px;

// line-height
$body-line-height: 24px;
$heading-line-height: 1.2;

body {
  font-size: $font-sm;
  font-family: $font-primary;
  line-height: $body-line-height;
  color: $neutral-700;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: $font-md;
  font-family: $font-secondary;
  line-height: $heading-line-height;
  color: $neutral-800;
}

h2 {
  font-size: $font-base;
  font-family: $font-secondary;
  line-height: $heading-line-height;
  color: $neutral-700;
  ;
}

h3 {
  font-size: $font-sm;
  font-family: $font-secondary;
  line-height: 1.2;
  color: $neutral-700;
}

label {
  font-size: $font-sm;
  color: $neutral-600;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}
