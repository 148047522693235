/* style */
$switch-width: 40px;
$switch-height: 24px;
$switch-padding: 3px;

/* animation */
$switch-animation-duration: 0.2s;

.switch-field {
  display: flex;
  align-items: center;

  label {
    font-weight: 400;

    &.bold {
      font-weight: 500;
    }
  }
}

.checkbox {
  display: flex;
  padding: $space-xs 0;
}

.switch {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  width: $switch-width;
  height: $switch-height;
  margin-right: $space-sm;
  padding: $switch-padding 0;
  border-radius: 50em;

  &.right {
    margin-right: 0;
    margin-left: $space-sm;
  }
}

.switch-input,
.switch-label {
  position: absolute;
  left: 0;
  top: 0;
}

.switch-input {
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked+.switch-label {
    background-color: $primary-500;
  }

  &:checked+.switch-label+.switch-marker {
    left: calc(100% - #{$switch-height} + #{$switch-padding});
  }
}

.switch-label {
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  background-color: $red-400;
  border-radius: inherit;
  z-index: 1;
  transition: background #{$switch-animation-duration};
}

.switch-marker {
  position: relative;
  background-color: #FFF;
  width: calc(#{$switch-height} - #{$switch-padding} * 2);
  height: calc(#{$switch-height} - #{$switch-padding} * 2);
  border-radius: 50%;
  z-index: 2;
  pointer-events: none;
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.25);
  left: $switch-padding;
  transition: left #{$switch-animation-duration};
  will-change: left;
}
