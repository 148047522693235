@font-face {
  font-family: 'Nucleo';
  src: url('/assets/fonts/Nucleo.eot');
  src: url('/assets/fonts/Nucleo.eot') format('embedded-opentype'), url('/assets/fonts/Nucleo.woff2') format('woff2'), url('/assets/fonts/Nucleo.woff') format('woff'), url('/assets/fonts/Nucleo.ttf') format('truetype'), url('/assets/fonts/Nucleo.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font: normal normal normal 20px/1 'Nucleo';
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-visit::before {
  content: "\ea03";
}

.icon-site::before {
  content: "\ea04";
}

.icon-zone::before {
  content: "\ea05";
}

.icon-sector::before {
  content: "\ea06";
}

.icon-client::before {
  content: "\ea07";
}

.icon-user::before {
  content: "\ea08";
}

.icon-settings::before {
  content: "\ea09";
}

.icon-logout::before {
  content: "\ea0a";
}

.icon-report::before {
  content: "\ea0b";
}

.icon-trash::before {
  content: "\ea0c";
}

.icon-error::before {
  content: "\ea0e";
}

.icon-bin::before {
  content: "\ea0f";
}

.icon-expand::before {
  content: "\ea10";
}

.icon-collapse::before {
  content: "\ea11";
}

.icon-list::before {
  content: "\ea12";
}

.icon-calendar::before {
  content: "\ea13";
}

.icon-download::before {
  content: "\ea14";
}

.icon-map::before {
  content: "\ea15";
}

.icon-folder::before {
  content: "\ea16";
}
