.app-container {
  height: 100%;
  width: 100%;
  display: flex;

  @media (max-width: $sm) {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: column;
  }
}

// menu is here  

.page-container {
  position: relative;
  flex: 1;
  display: flex;
  overflow: auto;
  background-color: $neutral-100;
}

// toolbar is here

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: $space-md;
}
