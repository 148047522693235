input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="date"],
select,
textarea {
  display: block;
  padding: $space-xs $space-sm;
  font-size: $font-sm;
  line-height: 20px;
  color: $neutral-700;
  background-color: $white;
  border: 1px solid $neutral-200;
  border-radius: $rounded;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: .3s all ease;
  box-shadow: $shadow-sm;

  &::placeholder {
    color: $neutral-400;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $neutral-700 !important;
  }

  &:-webkit-autofill::first-line {
    font-size: $font-sm
  }

  &:focus {
    border-color: $neutral-300;
    box-shadow: 0 0 0 3px $neutral-100;
  }

  &:disabled {
    background-color: $neutral-100;
  }
}

select {
  padding-right: $space-xl;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23D9E2EC' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - #{$space-xs});
  background-position-y: 50%;
}
