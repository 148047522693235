.modal {
  z-index: $zindex-popover;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  .frame {
    display: flex;
    flex-direction: column;
    width: 500px;
    max-height: 90vh;
    background-color: #FFF;
    border-radius: $rounded;
    box-shadow: $shadow;
    overflow: hidden;

    @media (max-width: $sm) {
      width: 100%;
      height: 100%;
      border-radius: $rounded-none;
    }
  }

  .card {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .header {
    display: flex;
    padding: $space-sm $space;
    border-bottom: 1px solid $neutral-100;

    .title {
      flex: 1;
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    .actions {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      margin-left: $space-xs;
      color: $neutral-700;
      background-color: $neutral-100;
      border-radius: $rounded;
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        background-color: $neutral-200;
      }

      &.hide {
        display: none;
      }

      .tooltip {
        z-index: $zindex-popover;
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        background-color: $white;
        border: 1px solid $neutral-200;
        border-radius: $rounded;
        box-shadow: $shadow-sm;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.8);
        transition: all .1s ease;

        &.visible {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }

        &:before {
          content: " ";
          position: absolute;
          display: block;
          bottom: 100%;
          right: 6px;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-bottom-color: $neutral-200;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          display: block;
          bottom: 100%;
          right: 7px;
          border: 7px solid transparent;
          border-bottom-color: white;
        }

        .action {
          padding: $space-xs $space-sm;
          white-space: nowrap;
          cursor: pointer;

          &.hide {
            display: none;
          }

          &.delete {
            color: $red-600;
            font-weight: 500;
          }

          &.ok {
            color: $green-600;
            font-weight: 500;
          }
        }
      }
    }

    .close {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      margin-left: $space-xs;
      color: $neutral-700;
      background-color: $neutral-100;
      border-radius: $rounded;
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        background-color: $neutral-100;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .content {
    flex: 1;
    padding: $space;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $neutral-100;
    }

    &::-webkit-scrollbar-thumb {
      background: $neutral-200;
    }

    input:not([type='checkbox']) {
      width: 100%;
    }
  }

  .commands {
    display: flex;
    justify-content: flex-end;
    padding: $space $space;
    text-align: right;
    background-color: $neutral-100;

    .spacer {
      flex: 1;
    }

    button {
      margin-left: $space-xs;
    }
  }

  div.errors p {
    color: $red-500;
    font-size: $font-xs;
  }

}
