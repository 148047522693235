// basics
$white: #FFFFFF;
$black: #3A3A3A;

// primary
// $primary-100: #F0FFF4;
// $primary-200: #C6F6D5;
// $primary-300: #9AE6B4;
// $primary-400: #68D391;
// $primary-500: #48BB78;
// $primary-600: #38A169;
// $primary-700: #2F855A;
// $primary-800: #276749;
// $primary-900: #22543D;

$primary-100: #FFD0B5;
$primary-200: #FFB088;
$primary-300: #FF9466;
$primary-400: #F9703E;
$primary-500: #F35627;
$primary-600: #DE3A11;
$primary-700: #C52707;
$primary-800: #AD1D07;
$primary-900: #841003;

$secondary-100: #EBF4FF;
$secondary-200: #C3DAFE;
$secondary-300: #A3BFFA;
$secondary-400: #7F9CF5;
$secondary-500: #667EEA;
$secondary-600: #5A67D8;
$secondary-700: #4C51BF;
$secondary-800: #434190;
$secondary-900: #3C366B;

// neutral
$neutral-050: #F8FAFC;
$neutral-100: #F0F4F8;
$neutral-200: #D9E2EC;
$neutral-300: #BCCCDC;
$neutral-400: #9FB3C8;
$neutral-500: #829AB1;
$neutral-600: #627D98;
$neutral-700: #486581;
$neutral-800: #334E68;
$neutral-900: #243B53;

// red
$red-100: #FFF5F5;
$red-200: #FED7D7;
$red-300: #FEB2B2;
$red-400: #FC8181;
$red-500: #F56565;
$red-600: #E53E3E;
$red-700: #C53030;
$red-800: #9B2C2C;
$red-900: #742A2A;

// orange
$orange-100: #FFFAF0;
$orange-200: #FEEBC8;
$orange-300: #FBD38D;
$orange-400: #F6AD55;
$orange-500: #ED8936;
$orange-600: #DD6B20;
$orange-700: #C05621;
$orange-800: #9C4221;
$orange-900: #7B341E;

// orange vivid
$orange-vivid-100: #FFD0B5;
$orange-vivid-200: #FFB088;
$orange-vivid-300: #FF9466;
$orange-vivid-400: #F9703E;
$orange-vivid-500: #F35627;
$orange-vivid-600: #DE3A11;
$orange-vivid-700: #C52707;
$orange-vivid-800: #AD1D07;
$orange-vivid-900: #841003;

// yellow
$yellow-100: #FFFFF0;
$yellow-200: #FEFCBF;
$yellow-300: #FAF089;
$yellow-400: #F6E05E;
$yellow-500: #ECC94B;
$yellow-600: #D69E2E;
$yellow-700: #B7791F;
$yellow-800: #975A16;
$yellow-900: #744210;

// green
$green-100: #F0FFF4;
$green-200: #C6F6D5;
$green-300: #9AE6B4;
$green-400: #68D391;
$green-500: #48BB78;
$green-600: #38A169;
$green-700: #2F855A;
$green-800: #276749;
$green-900: #22543D;

// teal
$teal-100: #E6FFFA;
$teal-200: #B2F5EA;
$teal-300: #81E6D9;
$teal-400: #4FD1C5;
$teal-500: #38B2AC;
$teal-600: #319795;
$teal-700: #2C7A7B;
$teal-800: #285E61;
$teal-900: #234E52;

// blue
$blue-100: #EBF8FF;
$blue-200: #BEE3F8;
$blue-300: #90CDF4;
$blue-400: #63B3ED;
$blue-500: #4299E1;
$blue-600: #3182CE;
$blue-700: #2B6CB0;
$blue-800: #2C5282;
$blue-900: #2A4365;

// indigo
$indigo-100: #EBF4FF;
$indigo-200: #C3DAFE;
$indigo-300: #A3BFFA;
$indigo-400: #7F9CF5;
$indigo-500: #667EEA;
$indigo-600: #5A67D8;
$indigo-700: #4C51BF;
$indigo-800: #434190;
$indigo-900: #3C366B;

// purple
$purple-100: #FAF5FF;
$purple-200: #E9D8FD;
$purple-300: #D6BCFA;
$purple-400: #B794F4;
$purple-500: #9F7AEA;
$purple-600: #805AD5;
$purple-700: #6B46C1;
$purple-800: #553C9A;
$purple-900: #44337A;

// pink
$pink-100: #FFF5F7;
$pink-200: #FED7E2;
$pink-300: #FBB6CE;
$pink-400: #F687B3;
$pink-500: #ED64A6;
$pink-600: #D53F8C;
$pink-700: #B83280;
$pink-800: #97266D;
$pink-900: #702459;

// gray
$gray-100: #F7FAFC;
$gray-200: #EDF2F7;
$gray-300: #E2E8F0;
$gray-400: #CBD5E0;
$gray-500: #A0AEC0;
$gray-600: #718096;
$gray-700: #4A5568;
$gray-800: #2D3748;
$gray-900: #1A202C;
